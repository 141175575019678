/* eslint-disable vue/no-unused-components */
<template>
  <div class="auto-withdraw">
    <header>
      <p class="auto-withdraw-title">
        <span class="line-mark"></span
        ><span class="auto-withdraw-title-img">证件照片</span>
      </p>
      <el-select
        v-model="form.industryQualificationCode"
        clearable
        placeholder="请选择行业"
        style="margin-left: 20px"
      >
        <el-option
          v-for="item in options"
          :key="item.code"
          :label="item.industryName"
          :value="item.code"
        >
        </el-option>
      </el-select>
      <ul class="auto-withdraw-photo-cards">
        <li
          style="width: 173px"
          v-for="(item, index) in imgUploadList"
          :key="index"
        >
          <div class="money-img-code" @click="changeHeadImg(item.url)">
            <img
              :src="form[item.url] ? form[item.url] : unploadImg"
              class="code-img-money"
              style="width: 173px; height: 113px"
            />
            <input
              class="hide"
              type="file"
              accept="image/*"
              @change="changeImg"
              style="display: none"
            />
          </div>
          <p>{{ item.title }}</p>
        </li>
      </ul>
    </header>
    <article>
      <p class="auto-withdraw-title">
        <span class="line-mark"></span
        ><span class="auto-withdraw-title-img">物资信息</span>
      </p>
      <div class="auto-withdraw-slice-line"></div>
      <!-- 表单部分 -->
      <el-form
        ref="form"
        :model="form"
        label-width="140px"
        style="padding: 20px"
        class="formStyle"
      >
        <el-row :gutter="24">
          <el-col :span="8">
            <el-form-item label="店铺名称">
              <el-input
                v-model="form.shopName"
                placeholder="依据营业执照填写"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="商家联系方式">
              <el-input
                v-model="form.businessPhone"
                placeholder="输入商家电话号码"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="营业执照名称">
              <el-input
                v-model="form.businessName"
                placeholder="输入营业执照名称"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="8" :offset="0">
            <el-form-item label="商家营业执照号">
              <el-input
                v-model="form.businessNumber"
                placeholder="输入商家营业执照号"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="0">
            <el-form-item label="法定代表人姓名">
              <el-input
                v-model="form.legalPersonName"
                placeholder="输入法定代表人姓名"
              ></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8" :offset="0">
            <el-form-item label="法定代表人身份证">
              <el-input
                v-model="form.legalPersonIdCardNumber"
                placeholder="输入法定代表人身份证"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row :gutter="24">
          <el-col :span="22" :offset="0">
            <el-form-item label="商家宣称语">
              <el-input
                type="textarea"
                v-model="form.shopDesc"
                placeholder="填写商家业务及优势即可,最多可输入120个字"
              ></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <footer>
          <PrevReclick>
          <div class="automatic-withdraw-submit-save pointer" @click="finaSave" v-if="status==1">
            上传资质
          </div>
        </PrevReclick>
          <div
            class="automatic-withdraw-submit-save pointer"
            style="background: #4d4d4d"
            @click="rejectOprate"

          >
            取消
          </div>
        </footer>
      </el-form>
    </article>
    <!-- 确认弹窗 -->

  </div>
</template>

<script>
import unploadImg from '@/assets/img/common/unploadImg.png';
import ChooseFile from '@base/utils/file/ChooseFile';
import FileUploader from '@base/utils/file/FileUploader';

import { $sg_listIndustryAndCode, $sg_uploadDetail, $sg_findVoByShopId } from '@/api/adversiting';

const imgUploadList = [
  {
    url: 'shopFacePicq',
    title: '商家门头照',
  },
  {
    url: 'idCardFront',
    title: '身份证正面照',
  },
  {
    url: 'idCardBack',
    title: '身份证背面照',
  },
  {
    url: 'businessPath',
    title: '营业执照照片',
  },
  {
    url: 'industryQualificationPic',
    title: '行业资质照片',
  },
];


export default {
  // eslint-disable-next-line no-undef
  components: { },
  data() {
    return {
      status: null,
      dialogs: {},
      shopId: null,
      showDialog: false,
      rejectReason: '',
      state: false,
      options: [{}],
      values: '',
      idTimeLong: false,
      businessTimePermanent: false,
      addressFlag: true,
      unploadImg,
      url: '',
      imgUploadList,
      imgUrlList: {
        shopFacePicq: '',
        idCardFront: '',
        idCardBack: '',
        businessPath: '',
        industryQualificationPic: '',
      },
      form: {
        shopName: '', businessName: '', businessNumber: '', legalPersonName: '', shopDesc: '', industryQualificationCode: '',
      },
      value: '',
      bankList: [],
      address: [],
      addTime: null,
      finAddress: null,
      addressList: [],
      dialogData: {
        title: 'dddddd',
        content: 'ddddddddddddd',
      },
    };
  },
  computed: {},
  watch: {
    // values(value, oldvalue) {
    //   if (!value) {
    //     this.imgUploadList.splice(4, 1);
    //   } else {
    //     this.imgUploadList.splice(4, 1, {
    //       url: 'industryQualificationPic',
    //       title: '行业资质照片',
    //     });
    //   }
    // },
  },
  created() {
    this.shopId = this.$route.query.shopId;
  },
  mounted() {
    this.getIndustry();
    this.status = this.$route.query.state;
    // eslint-disable-next-line eqeqeq
    if (this.status == 2) {
      this.baseData();
    }
  },
  // 方法集合
  methods: {
    baseData() {
      $sg_findVoByShopId({ shopId: this.shopId }).then((res) => {
        this.form = res;
      });
    },
    // 获取行业数据
    getIndustry() {
      $sg_listIndustryAndCode().then((res) => {
        this.options = res;
      });
    },
    sureSubmit() {
      this.$refs.exploreDialog.exploreVisible = false;
    },
    onSubmit() {},
    // 保存数据
    finaSave() {
      if (!this.form.industryQualificationCode) {
        this.$message.error('请选择行业');
        return;
      }
      // eslint-disable-next-line array-callback-return
      this.options.map((item) => {
        if (item.code === this.form.industryQualificationCode) {
          this.form.industryQualificationName = item.industryName;
        }
      });
      if (!this.form.shopFacePicq) {
        this.$message.error('请上传商家门头照');
        return;
      }
      if (!this.form.idCardFront) {
        this.$message.error('请上传身份证正面照');
        return;
      }
      if (!this.form.idCardBack) {
        this.$message.error('请上传身份证反面照');
        return;
      }
      if (!this.form.businessPath) {
        this.$message.error('请上传营业执照照片');
        return;
      }
      if (!this.form.industryQualificationPic) {
        this.$message.error('请上传行业资质照片');
        return;
      }

      if (!/^.{1,20}$/.test(this.form.shopName)) {
        this.$message.error('请输入店铺名称20个字符以内');
        return;
      }
      if (!/^\d{10,11}$/.test(this.form.businessPhone)) {
        this.$message.error('请输入正确商家联系方式');
        return;
      }

      if (!/^.{1,30}$/.test(this.form.businessName)) {
        this.$message.error('请输入营业执照名称30个字符以内');
        return;
      }
      if (!/^.{1,30}$/.test(this.form.businessNumber)) {
        this.$message.error('请输入营业执照号30个字符以内');
        return;
      }
      if (!/^.{1,30}$/.test(this.form.legalPersonName)) {
        this.$message.error('请输入法定代表人姓名30个字符以内');
        return;
      }
      if (!/^[a-zA-Z0-9]{16,18}$/.test(this.form.legalPersonIdCardNumber)) {
        this.$message.error('请输入正确身份证号码');
        return;
      }
      if (!/^.{1,120}$/.test(this.form.shopDesc)) {
        this.$message.error('请输入商家宣称语120个字符以内');
        return;
      }
      // eslint-disable-next-line array-callback-return
      this.form.shopId = this.shopId;
      $sg_uploadDetail(this.form).then(() => {
        this.$router.back();
      });
    },
    rejectOprate() {
      this.$router.go(-1);
    },
    changeImg(event) {
      const config = {
        file: event.target.files[0],
        width: 750,
      };
      event.target.value = null // eslint-disable-line
      this.$refs.imgCut.open(config);
    },
    changeHeadImg(url) {
      const change = new ChooseFile();
      change.chooseImage(this.getFile.bind(this, url));
    },
    getFile(urls, file) {
      console.log(urls, file, '124');
      const fileUploder = new FileUploader(file, {
        compress: false,
        addLibrary: false,
      });
      fileUploder
        .upload()
        .then((url) => {
          this.form[urls] = this.$imgPrefix(url, '/img/user/def_User_1.png');
          this.imgUploadList.splice();
        })
        .catch((error) => {
          console.error(error);
        });
    },
  },
};
</script>
<style lang='scss' >
.auto-withdraw {
  header {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 6px;
    .auto-withdraw-title {
      padding: 20px;
      .line-mark {
        display: inline-block;
        width: 3px;
        height: 19px;
        background: #ef3f46;

        margin-right: 10px;
      }
      .auto-withdraw-title-img {
        vertical-align: 3px;
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }
    }
    .auto-withdraw-warn {
      height: 35px;
      background: #ffe4e1;
      img {
        padding: 8px 10px 0 20px;
      }
      .auto-withdraw-warn-text {
        font-size: 12px;
        line-height: 35px;
        color: #f63d29;
      }
    }
    .auto-withdraw-photo-cards {
      width: 80%;
      padding: 20px 30px 30px 30px;
      display: flex;
      flex-flow: wrap;
      //   justify-content: space-between;
      li {
        margin-right: 80px;
      }
      div {
        cursor: pointer;
      }
      p {
        font-size: 14px;
        line-height: 40px;
        color: #999999;
        text-align: center;
      }
    }
    .industry-img {
      display: inline-block !important;
      width: unset !important;
      margin-left: 30px;
    }
  }
  article {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 6px;
    .auto-withdraw-title {
      padding: 20px;
      .line-mark {
        display: inline-block;
        width: 3px;
        height: 19px;
        background: #ef3f46;

        margin-right: 10px;
      }
      .auto-withdraw-title-img {
        vertical-align: 3px;
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }
    }
    .auto-withdraw-slice-line {
      height: 0px;
      border: 1px solid #e4e4e4;
    }
    .formStyle {
      padding: 20px;
      .el-input {
        width: 200px;
      }
    }
    footer {
      text-align: center;
      margin: 30px auto 0px auto;
      .automatic-withdraw-submit-save {
        background: #ef3f46;
        width: 128px;
        border-radius: 4px;
        font-size: 12px;
        line-height: 32px;
        color: #ffffff;
        display: inline-block;
        text-align: center;
        margin-right: 10px;
      }
    }
  }
  aside {
    p {
      font-size: 12px;
      line-height: 17px;
      color: #ef3f46;
    }

    margin-top: 20px;
    background: #ffffff;
    opacity: 1;
    border-radius: 6px;
    padding-bottom: 40px;
    .auto-withdraw-title {
      padding: 20px;
      .line-mark {
        display: inline-block;
        width: 3px;
        height: 19px;
        background: #ef3f46;

        margin-right: 10px;
      }
      .auto-withdraw-title-img {
        vertical-align: 3px;
        font-size: 18px;
        font-weight: bold;
        line-height: 25px;
      }
    }
    .auto-withdraw-slice-line {
      height: 0px;
      border: 1px solid #e4e4e4;
    }
    .formStyle {
      padding: 20px;
      .el-input {
        width: 180px;
      }
    }
  }
}
</style>
