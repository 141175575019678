<template>
  <div>
    <el-dialog
      class="advert-detail-dialog"
      :visible="advertDetailVisible"
      width="960px"
      @close="close('advertDetailVisible')"
      title="广告详情"
    >
      <!-- <el-button type="primary" size="default" @click="rushAdvert" style="float:right">更新数据</el-button>
       -->
      <ul class="advert-detail-type">
        <li
          v-for="item in dataClass"
          :key="item.id"
          :class="{ active: active == item.id }"
          @click="selectLi(item.id)"
        >
          {{ item.name }}
        </li>
       
      </ul>
      <el-table :data="tableData" style="width: 100%;margin-top:30px" border max-height="350px">
          <el-table-column prop="statisticsDate" label="日期"></el-table-column>
        <el-table-column prop="costAmount" label="花费(元)"></el-table-column>
        <el-table-column prop="viewTimes" label="观看量(次)"></el-table-column>
         <el-table-column
          prop="couponReceiveTimes"
          label="优惠券领取量(次)"
        ></el-table-column>
        <el-table-column
          prop="couponCostAmount"
          label="优惠券领取成本(元)"
        ></el-table-column>
        <el-table-column prop="cluesCollectTimes" label="线索搜集量(个)"></el-table-column>
        <el-table-column prop="cluesCollectCostAmount" label="线索搜集成本"></el-table-column>
      </el-table>
      <!-- <el-button type="primary" size="default" @click="rushAdvert" style="float:right;margin-top:30px">新增手机号</el-button> -->
        <p class="add-user-phone">新增用户手机号</p>
        <el-table :data="tablePhone" style="width: 100%;margin-top:30px" border max-height="550px">
        <el-table-column prop="keys" label="序号"></el-table-column>
        <el-table-column prop="createTime" label="时间"></el-table-column>
        <el-table-column
          prop="phone"
          label="电话号码"
        ></el-table-column>
      </el-table>
       <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
      </el-pagination>
    </el-dialog>
    <!-- 更新数据 -->
  <el-dialog
      class="advert-detail-dialog"
      :visible="rushDataVisible"
      width="660px"
      @close="close('rushDataVisible')"
      title="上传数据"
    >
        <p class="add-user-phone">昨日数据</p>
         <el-table :data="tableData" style="width: 100%;margin-top:30px" border max-height="350px">
        <el-table-column prop="orderId" label="花费(元)"></el-table-column>
        <el-table-column prop="shopName" label="观看量(次)"></el-table-column>
        <el-table-column
          prop="contactTel"
          label="优惠券领取成本(元)"
        ></el-table-column>
        <el-table-column prop="orderId" label="线索搜集量(个)"></el-table-column>
        <el-table-column prop="shopName" label="线索搜集成本"></el-table-column>
      </el-table>
      <p class="add-user-phone">今日数据</p>
         <el-table :data="tableData" style="width: 100%;margin-top:30px" border max-height="350px">
        <el-table-column prop="orderId" label="花费(元)"></el-table-column>
        <el-table-column prop="shopName" label="观看量(次)"></el-table-column>
        <el-table-column
          prop="contactTel"
          label="优惠券领取成本(元)"
        ></el-table-column>
        <el-table-column prop="orderId" label="线索搜集量(个)"></el-table-column>
        <el-table-column prop="shopName" label="线索搜集成本"></el-table-column>
      </el-table>
      <p class="add-user-phone">累计数据</p>
         <el-table :data="tableData" style="width: 100%;margin-top:30px" border max-height="350px">
        <el-table-column prop="orderId" label="花费(元)"></el-table-column>
        <el-table-column prop="shopName" label="观看量(次)"></el-table-column>
        <el-table-column
          prop="contactTel"
          label="优惠券领取成本(元)"
        ></el-table-column>
        <el-table-column prop="orderId" label="线索搜集量(个)"></el-table-column>
        <el-table-column prop="shopName" label="线索搜集成本"></el-table-column>
      </el-table>
     
    </el-dialog>
  </div>
</template>

<script>
import { $sg_getAdDataByType, $sg_pageShopAdPhone } from '@/api/adversiting';

const dataClass = [
  { name: '累计数据', id: 0 },
  { name: '昨日数据', id: 1 },
  { name: '今日数据', id: 2 },
];
const tableData = [];

export default {
  components: {},
  data() {
    return {
      tableData,
      dataClass,
      advertDetailVisible: false,
      active: null,
      rushDataVisible: false,
      totalPage: 1,
      currentPage: 1,
      tablePhone: [],
    };
  },
  props: {
    adId: {
      type: Number,

    },
  },
  computed: {},
  watch: {
    adId() {
      this.getAdDataByType({ type: 0, adId: this.adId });
      this.pageShopAdPhone({ pageSize: 5, pageNo: this.currentPage, adId: this.adId });
    },
  },
  created() {},
  mounted() {
    this.getAdDataByType({ type: 0, adId: this.adId });
    this.pageShopAdPhone({ pageSize: 5, pageNo: this.currentPage, adId: this.adId });
  },
  // 方法集合
  methods: {
    close(type) {
      this[type] = false;
    },
    selectLi(id) {
      this.active = id;
      this.getAdDataByType({ type: id, adId: this.adId });
    },
    // 更新数据
    rushAdvert() {
      this.rushDataVisible = true;
    },
    // 详情数据
    getAdDataByType(param = {}) {
      $sg_getAdDataByType(param).then((res) => {
        this.tableData = [];
        this.tableData.push(res);
      });
    },
    pageShopAdPhone(param = {}) {
      $sg_pageShopAdPhone(param).then((res) => {
        this.tablePhone = res.records;
        this.tablePhone.forEach((item, index) => {
          item.keys = index + 1 + (this.currentPage - 1) * 5;
        });
        this.totalPage = res.pages;
        console.log(res);
      });
    },
    pageChange() {
      this.pageShopAdPhone({ pageSize: 5, pageNo: this.currentPage, adId: this.adId });
    },
  },
};
</script>
<style lang='scss' >
.advert-detail-dialog {
  .el-dialog__header {
    background: #f8f7f7;
    padding-left: 35px !important;
  }
  .el-dialog__body {
    padding: 30px 35px;
  }
  .advert-detail-type {
    display: flex;
    .active {
      border: 1px solid rgba(246, 61, 42, 0.6);
      color: rgba(246, 61, 42, 1);
    }
    li {
      color: #666666;
      line-height: 32px;
      width: 135px;
      border: 1px solid rgba(102, 102, 102, 0.3);
      border-radius: 5px;
      margin-right: 20px;
      text-align: center;
      cursor: pointer;
    }
  }
  .add-user-phone{
      color: #666666;
      margin-top: 30px;
  }
}
</style>
