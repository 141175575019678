<template>
  <div class="createAd">
    <div class="container">
      <div class="box">
        <div class="title-box flexbox flex-align-center">
          <span class="line"></span>
          <span class="title">样式预览</span>
        </div>
        <div class="content-box style-box flexbox">
          <img :src="form.adPic" alt="" />
          <div class="style-preview">
            <!-- <div class="ad-shopName">干拌冒菜</div> -->
            <img :src="form.adPic" alt="" />
            <div class="des-box">
              <div class="title">{{ form.adTitle || '广告标题' }}</div>
              <div class="des">{{ form.adDesc || '广告文案' }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="container mt20">
      <div class="box">
        <div class="title-box flexbox flex-align-center">
          <span class="line"></span>
          <span class="title">广告图片</span>
        </div>
        <div class="content-box upload-box">
          <el-upload
            action="#"
            class="upload flexbox flex-justify-center flex-align-center"
            :http-request="img_ad_upload"
            :show-file-list="false"
            :disabled="disabled"
          >
            <img v-if="form.adPic" :src="form.adPic" class="upload-img" />
            <i v-else class="el-icon-plus avatar-uploader-icon"></i>
          </el-upload>
          <div class="upload-tips">建议尺寸：800px X 450px</div>
        </div>
      </div>
      <div class="box">
        <div class="title-box flexbox flex-align-center">
          <span class="line"></span>
          <span class="title">广告标题</span>
        </div>
        <div class="content-box">
          <el-input
            v-model="form.adTitle"
            :maxlength="10"
            :disabled="disabled"
            style="width: 330px"
            placeholder="请输入广告标题，至多可输入10个字"
          ></el-input>
        </div>
      </div>
      <div class="box">
        <div class="title-box flexbox flex-align-center">
          <span class="line"></span>
          <span class="title">广告文案</span>
        </div>
        <div class="content-box">
          <el-input
            v-model="form.adDesc"
            :maxlength="30"
            :disabled="disabled"
            style="width: 550px"
            placeholder="请输入广告文案，至多可输入30个字"
          ></el-input>
        </div>
      </div>
      <div class="box">
        <div class="title-box flexbox flex-align-center">
          <span class="line"></span>
          <span class="title">上线时间</span>
        </div>
        <div class="content-box">
          <el-date-picker
            type="date"
            v-model="form.onlineDateStr"
            value-format="yyyy-MM-dd"
            :picker-options="pickerOptions"
            placeholder="请选择上线时间"
            :disabled="disabled"
          ></el-date-picker>
          <div class="tips">
            当日6点之前选择可选T+4日期，当日6点之后仅可选择T+5日期，如果审核不通过则在重新提交资料后往后顺延。
          </div>
        </div>
      </div>
      <div class="box">
        <div class="title-box flexbox flex-align-center">
          <span class="line"></span>
          <span class="title">上线时段</span>
        </div>
        <div class="content-box">
          <div class="time-picker-box">
            <el-cascader
              v-model="onlineTimeStar"
              :options="optionsStartTime"
              clearable
              placeholder="选择开始时间"
              separator=":"
              :disabled="disabled"
            ></el-cascader>
            <span class="to">至</span>
            <el-cascader
              v-model="onlineTimeEnd"
              :options="optionsEndTime"
              clearable
              placeholder="选择结束时间"
              separator=":"
              :disabled="disabled"
            ></el-cascader>
          </div>
          <div class="tips">广告将集中投放每天的选择时间内。</div>
        </div>
      </div>
      <div class="box">
        <div class="title-box flexbox flex-align-center">
          <span class="line"></span>
          <span class="title">门店定位</span>
        </div>
        <div class="content-box">
          <el-input
            v-model="form.shopAddress"
            style="width: 330px; margin-right: 30px"
            clearable
            :disabled="disabled"
            @change="shopAddressChange"
            placeholder="请输入门店详细地址"
          ></el-input>
          <el-button
            type="primary"
            @click="openLocationDialog"
            :disabled="disabled"
            >获取定位</el-button
          >
        </div>
      </div>
      <div class="box">
        <div class="title-box flexbox flex-align-center">
          <span class="line"></span>
          <span class="title">活动内容</span>
        </div>
        <div class="content-box">
          <el-radio-group
            class="circle-radio1"
            v-model="form.activeState"
            :disabled="disabled"
          >
            <el-radio :label="1">折扣商品</el-radio>
            <el-radio :label="2">优惠券</el-radio>
            <el-radio :label="3">满赠活动</el-radio>
            <el-radio :label="4">新客立减</el-radio>
            <el-radio :label="5">会员积分</el-radio>
            <el-radio :label="6">满件减/折扣</el-radio>
          </el-radio-group>
        </div>
      </div>
      <div class="btn-box flexbox flex-align-center flex-justify-center">
        <el-button type="primary" class="btn cancle-btn" @click="$router.go(-1)"
          >取消</el-button
        >
        <PrevReclick>
          <el-button type="primary" class="btn sure-btn" @click="openPayDialog" :disabled="disabled"
            >确认</el-button
          >
        </PrevReclick>
      </div>
    </div>
    <!-- 获取定位弹窗 -->
    <el-dialog title="标记店铺" :visible.sync="locationDialog" :close-on-click-modal="false" width="1221px">
      <div>
        <el-input v-model="form.shopAddress" style="width:465px;" @change="searchAddress" placeholder="请输入门店详细地址"></el-input>
      </div>
      <div class="map-box">
        <div id="shopPointContainer" style="width:100%;height:549px;"></div>
      </div>
      <span slot="footer" class="dialog-footer flexbox flex-justify-center">
        <el-button type="primary" class="btn" @click="locationDialog = false">确认</el-button>
      </span>
    </el-dialog>
    <!-- 支付弹窗 -->
    <el-dialog title="支付" :visible.sync="payDialog" :close-on-click-modal="false" width="690px">
      <div class="payDialog-title flexbox flex-align-center">
        <span class="line"></span>
        <span class="title">投放时间</span>
      </div>
      <div class="money-box flexbox flex-justify-center flex-wrap">
        <div :class="showType == 1 ? 'money-item money-item-active' : 'money-item'" @click="updateShowType(1)">
          <p class="money">￥600.00</p>
          <p class="days">累计投放10天</p>
          <img src="/img/ad/pay.png" alt="" v-if="showType == 1">
        </div>
        <div :class="showType == 2 ? 'money-item money-item-active' : 'money-item'" @click="updateShowType(2)">
          <p class="money">￥2000.00</p>
          <p class="days">累计投放30天</p>
          <img src="/img/ad/pay.png" alt="" v-if="showType == 2">
        </div>
        <div :class="showType == 3 ? 'money-item money-item-active' : 'money-item'" @click="updateShowType(3)">
          <p class="money">￥5000.00</p>
          <p class="days">累计投放90天</p>
          <img src="/img/ad/pay.png" alt="" v-if="showType == 3">
        </div>
        <div :class="showType == 4 ? 'money-item money-item-active' : 'money-item'" @click="updateShowType(4)">
          <p class="money">￥9000.00</p>
          <p class="days">累计投放180天</p>
          <img src="/img/ad/pay.png" alt="" v-if="showType == 4">
        </div>
      </div>
      <div class="pay-money">
        <span>付款金额：</span>
        <span v-if="showType == 1">￥600.00</span>
        <span v-else-if="showType == 2">￥2000.00</span>
        <span v-else-if="showType == 3">￥5000.00</span>
        <span v-else-if="showType == 4">￥9000.00</span>
      </div>
      <img class="pay-img" :src="wxCodeUrl" alt="">
      <div class="pay-tips">请使用支付宝扫码支付</div>
      <span slot="footer" class="dialog-footer flexbox flex-justify-center">
        <PrevReclick>
          <el-button type="primary" class="btn" @click="pay">我已支付</el-button>
        </PrevReclick>
      </span>
    </el-dialog>
    <!-- 支付成功弹窗 -->
    <Dialog ref="dialogRef" @doneBtn="doneBtn"></Dialog>
  </div>
</template>
<script>
import { AMapUtils } from '@base/utils';
import Dialog from '@/components/common/dialog.vue';
import FileUploader from '@base/utils/file/FileUploader';
import {
  $sp_addAd, $sg_findVoById, $sp_updateShowType, $sg_hasPay, 
} from '@/api/adversiting';

export default {
  data() {
    return {
      disabled: false,
      form: {
        shopId: '',
        adPic: '',
        adTitle: '',
        adDesc: '',
        onlineDateStr: '',
        onlineTimeStar: '',
        onlineTimeEnd: '',
        shopAddress: '',
        shopLat: '',
        shopLng: '',
        activeState: 1,
        adModel: 1,
      },
      showType: 1,
      id: '',
      pickerOptions: {
        disabledDate(time) {
          // 获取当天6点的时间戳
          const sixTime = new Date().setHours(6, 0, 0, 0);
          // 获取目前时间的时间戳
          const currentTime = new Date().getTime();
          // 当日6点之前选择可选T+4日期，当日6点之后仅可选择T+5日期
          if (currentTime >= sixTime) {
            return time.getTime() < Date.now() + 4 * 24 * 60 * 60 * 1000;
          }
          return time.getTime() < Date.now() + 3 * 24 * 60 * 60 * 1000;
        },
      },
      onlineTimeStar: '',
      onlineTimeEnd: '',
      optionsStartTime: [],
      optionsEndTime: [],
      locationDialog: false,
      aMapUtils: null,
      payDialog: false,
      wxCodeUrl: '',
      payState: '', // 是否支付成功1-成功 2-失败
    };
  },
  components: {
    Dialog,
  },
  created() {
    this.form.shopId = this.$route.query.shopId;
    const adId = this.$route.query.adId;
    if (adId) {
      // 广告预览
      this.disabled = true;
      this.getAdDetail(adId);
    }
  },
  mounted() {
    const that = this;
    that.$nextTick(() => {
      that.datePickerData();
    });
  },
  methods: {
    // 查看广告详情
    getAdDetail(adId) {
      $sg_findVoById(adId).then((res) => {
        this.form = res;
        this.form.onlineDateStr = res.onlineDate.split(' ')[0];
        this.onlineTimeStar = [
          res.onlineTimeStar - (res.onlineTimeStar % 60),
          res.onlineTimeStar % 60,
        ];
        this.onlineTimeEnd = [
          res.onlineTimeEnd - (res.onlineTimeEnd % 60),
          res.onlineTimeEnd % 60,
        ];
        const from = this.$route.query.from;
        if (from === 'adlist') {
        // 广告支付
          this.id = this.form.id;
          this.updateShowType(1);
          this.payDialog = true;
        }
      });
    },
    // 设置时间
    datePickerData() {
      const arr = [];
      for (let i = 0; i < 24; i += 1) {
        const json = {
          label: i < 10 ? `0${i}` : i,
          value: i * 60,
          children: [],
        };
        for (let k = 0; k < 60; k += 1) {
          json.children.push({
            label: k < 10 ? `0${k}` : k,
            value: k,
          });
        }
        arr.push(json);
      }
      this.optionsStartTime = arr;
      this.optionsEndTime = arr;
    },
    // 门店详细地址改变
    shopAddressChange() {
      this.form.shopLat = '';
      this.form.shopLng = '';
    },
    // 打开获取定位弹窗
    openLocationDialog() {
      const self = this;
      if (!self.form.shopAddress) {
        self.$message.error('请输入门店详细地址');
        return;
      }
      self.locationDialog = true;
      self.$nextTick(() => {
        self.initMap();
      });
    },
    // 绘制地图
    initMap() {
      this.aMapUtils = new AMapUtils('shopPointContainer');
      this.aMapUtils.cityMap().then(() => {
        this.searchAddress();
        this.aMapUtils.mapAddEvent((map_lon, map_lat) => {
          this.aMapUtils.getAddress({ map_lon, map_lat }).then((res) => {
            this.form.shopLng = map_lon;
            this.form.shopLat = map_lat;
            this.$nextTick(() => {
              this.form.shopAddress = res;
              this.addressList = [{
                location: {
                  lng: this.form.shopLng,
                  lat: this.form.shopLat,
                },
              }];
              this.resetMarker();
            });
          });
        });
      });
    },

    // 搜索地址
    searchAddress() {
      this.aMapUtils.search(this.form.shopAddress).then((res) => {
        if (res.pois.length) {
          this.form.shopLng = res.pois[0].location.lat;
          this.form.shopLat = res.pois[0].location.lng;
        }
        this.addressList = res.pois;
        this.resetMarker();
      });
    },

    // 重置标记
    resetMarker() {
      if (this.markList && this.markList.length > 0) {
        this.aMapUtils.map.remove(this.markList);
      }
      this.markList = [];
      this.addressList.forEach((address) => {
        this.markList.push(this.aMapUtils.addMaker([address.location.lng, address.location.lat]));
      });
      this.aMapUtils.map.setFitView(this.markList);
    },
    // 上传广告图片
    async img_ad_upload(file) {
      const arr = file.file.name.split('.');
      const fileUploder = new FileUploader(file.file, { addLibrary: false, compress: true, ext: arr[arr.length - 1] });
      fileUploder.upload().then((url) => {
        this.form.adPic = url;
      }).catch((error) => {
        this.$message.error(error.message);
      });
    },
    // 打开支付弹窗
    async openPayDialog() {
      await this.$validator({
        ...this.form,
      }, [
        'adPic/require/广告图片',
        'adTitle/require/广告标题',
        'adDesc/require/广告文案',
        'onlineDateStr/require/上线时间',
        'shopAddress/require/门店详细地址',
        'shopLat/require/门店定位',
        'shopLng/require/门店定位',
      ]);
      const startTime = this.onlineTimeStar;
      const endTime = this.onlineTimeEnd;
      if (!startTime || startTime.length < 2) {
        this.$message.error('请选择开始时间');
        return;
      }
      if (!endTime || endTime.length < 2) {
        this.$message.error('请选择结束时间');
        return;
      }
      this.form.onlineTimeStar = startTime[0] + startTime[1];
      this.form.onlineTimeEnd = endTime[0] + endTime[1];
      if (this.form.onlineTimeStar >= this.form.onlineTimeEnd) {
        this.$message.error('结束时间必须大于开始时间');
        return;
      }
      if (this.form.adTitle.length > 10) {
        this.$message.error('广告标题最多输入10个字');
        return;
      }
      if (this.form.adDesc.length > 30) {
        this.$message.error('广告文案最多输入30个字');
        return;
      }
      const params = {
        ...this.form,
      };
      $sp_addAd(params).then((res) => {
        // 新增后返回的广告主键id
        this.id = res;
        this.payDialog = true;
        this.updateShowType(1);
        Object.keys(this.form).forEach((item) => {
          this.form[item] = '';
        });
        this.onlineTimeStar = '';
        this.onlineTimeEnd = '';
        this.form.activeState = 1;
        this.form.adModel = 1;
      });
    },
    // 更新广告投放类型
    updateShowType(val) {
      this.showType = val;
      const params = {
        id: this.id,
        showType: val,
      };
      $sp_updateShowType(params).then((res) => {
        // 获取支付二维码
        this.wxCodeUrl = res;
      });
    },
    // 支付
    pay() {
      $sg_hasPay(this.id).then((res) => {
        this.payDialog = false;
        let icon = '/img/ad/printFail.png';
        let title = '支付失败!';
        let content = '支付失败，请重新支付';
        let done = '重新支付';
        this.payState = 2; // 是否支付成功 1-成功，2-失败
        if (res) {
          icon = '/img/ad/printSucess.png';
          title = '支付成功!';
          content = '松鼠后台会根据微信要求适当优化广告内容。微信审核通过后即可在朋友圈展示该广告';
          done = '好的';
          this.payState = 1;
        }
        this.$refs.dialogRef.dialogs = {
          icon,
          title,
          content,
          done,
        };
        setTimeout(() => {
          this.$refs.dialogRef.showDialog = true;
        }, 300);
      });
    },
    // 关闭支付成功/失败弹窗
    doneBtn() {
      if (this.payState === 1) {
        this.$router.go(-1);
      } else if (this.payState === 2) {
        this.payDialog = true;
        this.updateShowType(this.showType);
      }
    },
  },
};
</script>
<style lang="scss">
@import "@/style/var.scss";
.createAd {
  .container {
    width: 100%;
    background-color: #fff;
    border-radius: 6px;
    .box {
      width: 100%;
      padding: 20px 20px 30px 20px;
      box-sizing: border-box;
      .title-box {
        width: 100%;
        .line {
          width: 3px;
          height: 19px;
          display: inline-block;
          background-color: $--theme-color;
        }
        .title {
          display: inline-block;
          font-size: 18px;
          font-weight: 500;
          color: #444444;
          margin-left: 12px;
        }
      }
      .content-box {
        width: 100%;
        margin-top: 20px;
        .tips {
          margin-top: 15px;
          font-size: 14px;
          color: #999;
        }
        .time-picker-box {
          .to {
            color: #999999;
            margin: 0px 13px;
          }
        }
      }
      .style-box {
        & > img {
          width: 150px;
          height: 150px;
          border-radius: 6px;
        }
        .style-preview {
          width: 352px;
          height: 269px;
          margin-left: 30px;
          .ad-shopName{
            font-size: 12px;
            color: #444;
            line-height: 17px;
          }
          & > img {
            width: 100%;
            height: 198px;
            border-top-left-radius: 6px;
            border-top-right-radius: 6px;
          }
          .des-box {
            width: 100%;
            height: 71px;
            background-color: #f6f6f6;
            padding: 15px 20px;
            box-sizing: border-box;
            .title {
              color: #444;
              font-size: 17px;
              line-height: 24px;
            }
            .des {
              color: #999;
              font-size: 12px;
              line-height: 17px;
            }
          }
        }
      }
      .upload-box {
        .upload {
          width: 364px;
          height: 205px;
          background-color: #f5f5f5;
        }
        .avatar-uploader-icon {
          font-size: 50px;
          color: #aaa;
        }
        .upload-img {
          width: 364px;
          height: 205px;
        }
        .upload-tips {
          font-size: 18px;
          color: #999;
          margin-top: 20px;
          margin-left: 73px;
        }
      }
    }
    .btn-box {
      width: 100%;
      margin-top: 80px;
      background-color: #fff;
      padding-bottom: 40px;
      .btn {
        width: 128px;
        height: 32px;
        line-height: 32px;
        padding: 0px;
      }
      .cancle-btn {
        background-color: #4d4d4d;
        border-color: #4d4d4d;
      }
    }
    //el-radio 圆形
    .circle-radio1 {
      .el-radio__inner {
        width: 18px;
        height: 18px;
        &::after {
          font-family: "element-icons" !important;
          font-size: 12px;
          font-style: normal;
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          content: "\e6da";
          background-color: transparent !important;
          color: #fff;
          display: inline-block;
          width: 12px;
          height: 10px;
          font-weight: 600;
        }
      }
      .el-radio__inner::after {
        color: #d4d4d4;
      }
    }
  }
  .map-box {
    width: 100%;
    height: 549px;
    margin-top: 20px;
  }
  .dialog-footer .btn {
    width: 140px;
    height: 32px;
    line-height: 1;
    padding: 0px;
  }
  // 支付弹窗
  .payDialog-title {
    width: 100%;
    .line {
      width: 3px;
      height: 19px;
      display: inline-block;
      background-color: $--theme-color;
    }
    .title {
      display: inline-block;
      font-size: 18px;
      font-weight: 500;
      color: #444444;
      margin-left: 12px;
    }
  }
  .money-box {
    width: 100%;
    .money-item {
      width: 295px;
      height: 109px;
      border-radius: 4px;
      border: 1px solid #e4e4e4;
      margin-top: 15px;
      margin-right: 30px;
      cursor: pointer;
      user-select: none;
      box-sizing: border-box;
      .money {
        width: 100%;
        text-align: center;
        font-size: 26px;
        color: #444444;
        font-weight: 500;
        margin-top: 24px;
      }
      .days {
        width: 100%;
        text-align: center;
        font-size: 14px;
        color: #999999;
        margin-top: 10px;
      }
    }
    & > .money-item:nth-child(2n) {
      margin-right: 0px;
    }
    .money-item-active {
      border: 1px solid $--theme-color;
      position: relative;
      .money {
        color: $--theme-color;
      }
      img {
        width: 29px;
        height: 27px;
        position: absolute;
        bottom: -1px;
        right: -1px;
      }
    }
  }
  .pay-money {
    width: 100%;
    text-align: center;
    margin-top: 28px;
    font-size: 17px;
    color: #444;
    & > span:last-child {
      color: $--theme-color;
    }
  }
  .pay-img {
    width: 146px;
    height: 145px;
    display: block;
    margin: 0 auto;
    margin-top: 19px;
  }
  .pay-tips {
    width: 100%;
    text-align: center;
    margin-top: 15px;
    font-size: 14px;
    color: #999;
  }
  // 修改弹窗头部样式
  .el-dialog__header {
    height: 47px;
    line-height: 47px;
    padding: 0px 35px;
    background-color: #f8f7f7;
    border-radius: 6px;
    .el-dialog__title {
      font-size: 16px;
      color: #333;
      font-weight: 400;
    }
    .el-dialog__headerbtn {
      top: 15px;
    }
  }
}
</style>
