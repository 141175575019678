<template>
  <div class="advertising-list">
    <header class="adver-header">
      <aside class="adver-desc">
        <p class="adver-desc-title">朋友圈广告介绍</p>
        <p class="adver-desc-content">
          朋友圈广告，是微信朋友圈广告推出的、专门针对线下门店的，外卖营销推广解决方案。可满足商家小预算、低成本，操作简单、持续不断引流外卖
          的推广诉求。<br>
          对于本地商户而言是不可或缺的投放机会，在拓宽商户售卖渠道的同时，更好的服务于本地为用户，是把生活与营销相结合的一个好产品。做外卖的商户，想做宣传的话，朋友圈广告是你的不二选择。
        </p>
      </aside>
      <p class="adver-example-title">
        <span class="adver-line-remark"></span><span class="adver-example-title-text">案例展示</span>
      </p>
      <ul class="adver-example-list">
        <li>
          <div>
            <img src="@/assets/img/adverImg.png" alt="" />
          </div>
          <footer>
            <p class="adver-goods-name">干拌冒菜</p>
            <p class="adver-goods-desc">新用户免费领取新礼包</p>
          </footer>
        </li>
        <li>
          <div>
            <img src="@/assets/img/coffeeEg.png" alt="" />
          </div>
          <footer>
            <p class="adver-goods-name">英伦咖啡</p>
            <p class="adver-goods-desc">发放到店优惠券，促销热门单品，吸引用户到店消费。</p>
          </footer>
        </li>
        <li>
          <div>
            <img src="@/assets/img/betterShop.png" alt="" />
          </div>
          <footer>
            <p class="adver-goods-name">佳佳超市</p>
            <p class="adver-goods-desc">通过有效的营销活动吸引用户成为会员，增加粘性。</p>
          </footer>
        </li>
        <li>
          <div>
            <img src="@/assets/img/riceNoodle.png" alt="" />
          </div>
          <footer>
            <p class="adver-goods-name">过桥米线</p>
            <p class="adver-goods-desc">通过有效的营销活动吸引用户成为会员，增加粘性。</p>
          </footer>
        </li>
      </ul>
      <ul class="adver-example-desc">
        <li>
          <p>客户痛点：新店没人气，如何寻找新用户到店消费？</p>
          <p>解决方案：低门槛新人礼包到店享，引爆点击。</p>
        </li>
        <li>
          <p>客户痛点：线下客流量低，如何寻找用户到店消费？</p>
          <p>解决方案：推出主打单品，优化广告文案。</p>
        </li>
        <li>
          <p>客户痛点：行业同质化严重，难以吸引提升营业额。</p>
          <p>解决方案：推出高优惠力度的活动方案吸引用户成为会员。</p>
        </li>
        <li>
          <p>客户痛点：门店位置偏僻，难以获取更多的用户。</p>
          <p>解决方案：推出外卖活动优惠券，提升外卖营业额。</p>
        </li>
      </ul>
    </header>
    <aside class="adver-list-content">
      <div class="avder-list-content-status">
        <p class="adver-list-content-money">
          <span class="adver-list-content-money-text">当前余额:</span><span class="adver-list-content-money-num">{{banlance}}</span>元
        </p>
        <p class="adver-upload-data" @click="creatAd" :class="{ disabled:openState != 5 }">
          创建广告
        </p>
        <p class="adver-upload-data" @click="unploadData"  :class="{ disabled: (openState == 1||openState ==4)}"
          style="margin-right:10px">
          {{ ficationState[openState] }}
        </p>

      </div>

      <el-table stripe :data="tableData" border style="width: 100%">
        <el-table-column prop="createTime" label="创建时间" show-overflow-tooltip width="160">
           <template slot-scope="scope">
            <span>{{ formattingTime(scope.row.createTime) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="onlineDate" label="时间" width="160" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ formattingTime(scope.row.onlineDate) }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="takeawayCount" label="广告模式" width="140" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ scope.row.adModel == 0 ? '付费模式' : '系统赠送' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="adTitle" label="标题" width="120" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="verifyState" label="审核状态" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ (scope.row.verifyState||scope.row.verifyState==0)?verifyStateList[scope.row.verifyState]:'--' }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="showType" label="投放状态" width="120" show-overflow-tooltip>
          <template slot-scope="scope">
            <span>{{ showTypeList[scope.row.showType] }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="rejectMessage" label="驳回原因" width="120" show-overflow-tooltip>
        </el-table-column>

        <el-table-column prop="type" label="操作">
          <template slot-scope="scope">
            <span class="theme-color pointer" @click="openAdDetail(scope.row, 'enterShop')">详情</span>
             <el-divider direction="vertical"></el-divider>
               <span class="theme-color pointer" @click="lookCreatAd(scope.row, 'enterShop')">预览</span>
                <el-divider direction="vertical" v-if="!scope.row.payState"></el-divider>
               <span class="theme-color pointer" v-if="!scope.row.payState" @click="payAd(scope.row, 'enterShop')">去支付</span>
          </template>
        </el-table-column>
      </el-table>
      <el-pagination class="mt10" background :page-count="totalPage" :current-page.sync="currentPage" @current-change="pageChange">
      </el-pagination>
    </aside>
    <advertising-detail ref="adDetail" :adId='adId' v-if='openShow' />
  </div>
</template>

<script>
import {
  $sg_adversitBanlance,
  $sg_checkUploadQualificationState,
  $sg_pageShopAd,
} from '@/api/adversiting';
import advertisingDetail from './components/advertisingDetail.vue';

const ficationState = ['上传资质', '审核中', '', '', '审核中', '查看资质', '重新上传'];
const verifyStateList = ['未支付', '松鼠审核中', '制作完成', '松鼠审核通过', '松鼠审核未通过', '微信审核中', '微信审核通过', '微信审核未通过'];
const showTypeList = ['', '10天600元', '30天2000元', '90天5000元', '180天9000元'];
export default {
  components: { advertisingDetail },
  data() {
    return {
      ficationState,
      openState: null,
      tableData: [],
      totalPage: 1,
      currentPage: 1,
      verifyStateList,
      showTypeList,
      adId: null,
      rushState: null,
      openShow: false,
      banlance: '',
    };
  },
  computed: {},
  watch: {},
  created() { this.shopId = this.$route.query.shopId; },
  mounted() {
    this.adversitBanlance();
    this.checkUploadQualificationState();
    this.pageAdList();
  },
  // 方法集合
  methods: {
    // 支付广告费用
    payAd(item) {
      this.$router.push({
        name: 'CreateAd',
        query: { from: 'adlist', adId: item.id, shopId: this.shopId },
      });
    },
    // 预览广告
    lookCreatAd(item) {
      this.$router.push({
        name: 'CreateAd',
        query: { adId: item.id, shopId: this.shopId },
      });
    },
    unploadData() {
      // eslint-disable-next-line eqeqeq
      if (this.openState == 0 || this.openState == 6) {
        this.$router.push({
          name: 'AdvertisingQualification',
          query: { state: '1', shopId: this.shopId },
        });
      }
      //  eslint-disable-next-line eqeqeq
      if (this.openState == 5) {
        this.$router.push({
          name: 'AdvertisingQualification',
          query: { state: '2', shopId: this.shopId },
        });
      }
      // eslint-disable-next-line eqeqeq
    },
    // 创建广告
    creatAd() {
      // eslint-disable-next-line eqeqeq
      if (this.openState == 5) {
        this.$router.push({
          name: 'CreateAd',
          query: { shopId: this.shopId },
        });
      } else {
        this.$message.error('请先上传资质并审核成功');
      }
    },
    // 获取剩余广告费用
    adversitBanlance() {
      $sg_adversitBanlance({ shopId: this.shopId }).then((res) => {
        this.banlance = res;
      });
    },
    // 审核状态
    checkUploadQualificationState() {
      $sg_checkUploadQualificationState({ shopId: this.shopId }).then((res) => {
        this.openState = res;
      });
    },
    // 广告列表 $sg_pageShopAd
    pageAdList() {
      $sg_pageShopAd({ pageNo: this.currentPage, pageSize: 10, shopId: this.shopId }).then((res) => {
        this.tableData = res.records;
        this.totalPage = res.pages;
      });
    },
    // 翻页
    pageChange() {
      this.pageAdList();
    },
    // 打开详情
    openAdDetail(item) {
      this.openShow = true;
      this.adId = item.id;
      console.log(this.adId, '3443');
      this.$nextTick(() => {
        this.$refs.adDetail.advertDetailVisible = true;
      });
    },
    formattingTime(time) {
      const date = new Date(time);
      return this.$formatDate((date.getTime()) / 1000, 'yyyy-MM-dd HH:mm');
    },
  },
};
</script>
<style lang='scss' >
.advertising-list {
  .disabled {
    background: #dddddd !important;
  }
  .adver-header {
    min-height: 300px;
    background: #ffffff;
    border-radius: 6px;
    padding: 15px;
    .adver-desc {
      height: 61px;
      background: #f6f6f6;
      border-radius: 6px;
      padding: 15px;
      .adver-desc-title {
        font-size: 12px;
        font-weight: 500;
        color: #444444;
        line-height: 17px;
      }
    }
    .adver-desc-content {
      padding-top: 10px;
      font-size: 12px;
      font-weight: 400;
      color: #444444;
    }
    .adver-example-title {
      margin-top: 20px;
      .adver-line-remark {
        width: 3px;
        height: 19px;
        background: #ef3f46;
        display: inline-block;
      }
      .adver-example-title-text {
        font-size: 18px;
        font-weight: 500;
        color: #444444;
        vertical-align: 3px;
        margin-left: 10px;
      }
    }
    .adver-example-list {
      margin-top: 10px;
      width: 95%;
      display: flex;
      justify-content: space-between;
      li {
        width: 352px;
        height: 269px;
        background: #f6f6f6;
        border-radius: 6px;
        footer {
          padding: 20px;
          .adver-goods-name {
            font-size: 17px;
            color: #444444;
          }
          .adver-goods-desc {
            font-size: 12px;
            color: #999999;
          }
        }
      }
    }
    .adver-example-desc {
      margin-top: 10px;
      width: 95%;
      display: flex;
      justify-content: space-between;
      li {
        min-width: 352px;
        color: #444444;
      }
    }
  }
  .adver-list-content {
    margin-top: 20px;
    background: #ffffff;
    border-radius: 6px;
    min-height: 300px;
    padding: 30px 20px;
    .avder-list-content-status {
      .adver-list-content-money {
        float: left;
      }
      .adver-list-content-money-text {
        font-size: 17px;
      }
      .adver-list-content-money-num {
        font-size: 34px;
        font-weight: 500;
        color: #f63d2a;
      }
      .adver-upload-data {
        float: right;
        height: 40px;
        width: 245px;
        background: #f63d2a;
        line-height: 40px;
        text-align: center;
        color: #ffffff;
        border-radius: 5px;
        cursor: pointer;
      }
    }
  }
}
</style>
